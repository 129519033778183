/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

if ("serviceWorker" in navigator) {

  // Tests if the user agent is Chromium-based, and if so, registers the service worker.
  if (/chrome/i.test(navigator.userAgent)) {
    window.addEventListener("load", function() {
      navigator.serviceWorker.register("/service-worker.js", {
        scope: "/",
      }).then(function(registration) {
        console.log("SW ok", registration);
      }).catch(function(error) {
        console.error("SW error", error);
      });
    });
  } else {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });

    console.log("SW skipped in Firefox and Safari");
  }
}
